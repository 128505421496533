// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("src/assets/images/title-background.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".title[data-v-24850e42]{width:20rem;height:1.70833rem;box-sizing:border-box;padding-left:1.95833rem;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:no-repeat;background-position:50%;background-size:100% 100%;display:flex;align-items:center;justify-content:space-between}.title .title-text[data-v-24850e42]{font-size:.83333rem;line-height:.95833rem;text-shadow:inset 0 0 1px hsla(0,0%,100%,.5);background:linear-gradient(180deg,#fff,#00b4ff);font-weight:700;-webkit-background-clip:text;-webkit-text-fill-color:transparent}", ""]);
// Exports
module.exports = exports;
