<template>
  <div class="app">
    <router-view />
  </div>
</template>
<script>

export default {
  methods: {

  },
  created() {

  },
  async mounted() {

  },
};
</script>
