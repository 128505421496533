export const img_url ='https://endwork.lsnf.dasding.cn/app-api'
export const data_title ={
  
}
export const token ='eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjJhMmE3MWIyLWUwNmYtNGI1MS1iOTYzLTM4NmQzNTNiZjI3MyJ9.okx2yF4xy9FC2ChiH04TeqV-YVbxfWANpwbVQxKHiTLoVva2l80kzpJpXbupbvJRXMI5wr1Ot5AGJ2Zw4PXh7g'

export const szzy = [
    {
        label: '东田未来乡村驾驶舱',
        url: "https://dt.bigscreen.dasding.cn/",
        img: require('@/assets/images/registration.png')
    },
    {
        label: '产业数据中心',
        url: "https://vivid.saas.ictsoft.cn/dplus/view/1701533429436198913?note=9v1yp6aorp&hasPassword=0",
        img: require('@/assets/images/knowledge.png')
    },
]