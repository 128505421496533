<template>
  <div class="normal-layout">
    <!-- <i class="left_"></i> -->
    <!-- <i class="right_"> </i> -->
    <!-- <i class="bottom_"></i> -->
    <header-component>
      <slot name="header" />
    </header-component>
    <div style="display: flex; align-items: center;justify-content: space-between">
      <left-component>
        <slot name="left"></slot>
      </left-component>
      <middle-component>
        <slot name="middle"> </slot>
      </middle-component>
      <right-component>
        <slot name="right"></slot>
      </right-component>
        <tc>
        <slot name="tc"></slot>
      </tc>
    </div>
  </div>
</template>

<script>
import leftComponent from "./left";
import rightComponent from "./right";
import middleComponent from "./middle/index.vue";
import headerComponent from "./header";
import tc from "./tc";
export default {
  components: {
    leftComponent,
    middleComponent,
    rightComponent,
    headerComponent,
    tc
  },
};
</script>

<style lang="scss">
.normal-layout {
  position: relative;
  width: 100%;
  //height: 100%;
  left: 0;
  top: 0;
  overflow: hidden;
  //padding-bottom: px2vh(42);

  background: linear-gradient(
    90deg,
    rgba(2, 5, 16, 0.77) 13%,
    rgba(2, 5, 16, 0) 31%,
    rgba(0, 0, 0, 0) 51%,
    rgba(2, 5, 16, 0) 71%,
    rgba(2, 5, 16, 0.77) 90%
  );
  opacity: 1;
  // 禁止 normal-layout 内部所有dom元素的鼠标事件
  // 除非该元素拥有class touch-enable
  // 防止各种dom元素 挡住gis地图 影响地图的操作
  pointer-events: none;
   @include background-image('~src/assets/images/bgnew@2x.png');

  .touch-enable {
    pointer-events: auto;
  }
  .left_ {
    position: absolute;
    left: px2vw(5);
    top: 0;
    width: px2vw(165);
    height: 100%;
    background-image: url("~src/assets/images/jsc/left_border.png");
    background-size: 100% 100%;
    z-index: 1;
  }
  .right_ {
    position: absolute;
    right: px2vw(5);
    top: 0;
    width: px2vw(165);
    height: 100%;
    background-image: url("~src/assets/images/jsc/right_border.png");
    background-size: 100% 100%;
     z-index: 1;
  }
  .bottom_ {
    position: absolute;
    left: 50%;
    bottom: 0;
      transform: translate3d(-50%, 0, 0);
    width: px2vw(1524);
    height: px2vh(43);
    background-image: url("~src/assets/images/jsc/bottom.png");
    background-size: 100% 100%;
     z-index: 1;
  }
}

</style>
