<template>
  <div class="title touch-enable" @click="$emit('click')">
    <span class="title-text">{{ title }}</span>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped lang="scss">
.title {
  width: px2vw(480);
  height: px2vh(41);
  box-sizing: border-box;
  padding-left: px2vw(47);
  @include background-image("~src/assets/images/title-background.png");
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title-text {
    font-size: px2vh(20);
    line-height: px2vh(23);

    text-shadow: inset 0px 0px 1px rgba(255, 255, 255, 0.5);

    background: linear-gradient(180deg, #ffffff 0%, #00b4ff 100%);
    font-weight: bold;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>
