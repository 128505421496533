import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/main",
  },

  {
    path: '/main', //产业引用
    name: 'main',
    component: () =>
      import(/* webpackChunkName: "modules/cyyy" */ "../views/main/index.vue"),
  },
  {
     path: '/map', //产业引用
       name: 'map',
       component: () =>
       import( /* webpackChunkName: "modules/cyyy" */ "../views/map/index.vue"),
  }
];

const router = new VueRouter({
  routes,
});

export default router;
