// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("src/assets/images/jsc/top_border.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".header{z-index:2}.header,.header-content-bg{position:absolute;left:0;top:0;width:100%;height:4.29167rem}.header-content-bg{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:100% 100%}.header-title-text{position:absolute;left:50%;top:46%;transform:translate3d(-50%,-50%,0);margin-top:-.41667rem;text-shadow:inset 0 0 1px hsla(0,0%,100%,.5);background-image:linear-gradient(180deg,#fff,#00b4ff);overflow-wrap:break-word;font-size:2rem;font-family:ysbth;color:#fff;text-align:center;white-space:nowrap;letter-spacing:.125rem;-webkit-background-clip:text;-webkit-text-fill-color:transparent}@-webkit-keyframes pulsate-fwd{0%{transform:scaleX(1)}30%{transform:scale3d(.8,1.25,1);opacity:1}40%{transform:scaleY(.75);opacity:.5}50%{transform:scale3d(.2,1.15,1);opacity:0}65%{transform:scaleY(.95);opacity:.5}75%{transform:scale3d(.8,1.05,1);opacity:1}to{transform:scaleX(1)}}@keyframes pulsate-fwd{0%{transform:scaleX(1)}30%{transform:scale3d(.8,1.25,1);opacity:1}40%{transform:scaleY(.75);opacity:.5}50%{transform:scale3d(.2,1.15,1);opacity:0}65%{transform:scaleY(.95);opacity:.5}75%{transform:scale3d(.8,1.05,1);opacity:1}to{transform:scaleX(1)}}@-webkit-keyframes pulsates{0%{left:34%}to{left:48%}}@keyframes pulsates{0%{left:34%}to{left:48%}}._left_box{width:25rem;position:relative;display:flex;align-items:center;top:2rem;left:.83333rem}._left_box .time_left{width:7.625rem;height:1.5rem;display:flex;align-items:center;justify-content:center;background:linear-gradient(270deg,rgba(36,108,225,0),rgba(39,90,172,.6) 52%,rgba(58,114,203,0));overflow-wrap:break-word;color:#fff;font-size:.83333rem;font-family:PingFangSC-Medium;font-weight:500;text-align:left;white-space:nowrap}._left_box .time_left .time_img{width:.625rem;height:.66667rem}._left_box .time_left .text_img{margin-left:.25rem}.margin-l17{margin-left:.70833rem}", ""]);
// Exports
module.exports = exports;
