<template>
  <div class="header touch-enable">
    <i class="header-content-bg"></i>
    <span class="header-title-text">{{ commonHeadTitle }}</span>
    <div class="_left_box">
      <div class="time_left">
        <img
          class="time_img"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAQCAYAAADJViUEAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAD6ADAAQAAAABAAAAEAAAAADlhttaAAABBElEQVQoFWNggAL3Obdnecy+/R/GR6exyTO6zLzLz8z8z4+R4X8aw39GG0YGxnR0jSD+f8Z/sWD5/4xpjAz/Tm9LU7vACLTtGFDOEpsGPGJ/f//8I84EVGCBRxEuKWZmViZRkGZGXCoIibOAFABD6TrQz4cJKYbKiwLtCwSxwZoZ/zMe2pGmmkGMZrfZd4yZGP4jNMM0AQNvCZD9f0eqaqzn7DvVQGbg9lRVE4/Zt4KAsVD199+/GJhaEA3yM9kAFFX/gfE3c0eaCinOPvPv3z9NimweoppB8fzjP+N/O2B0zCQu2P+BEgkDIzPzHxYg3cT4n6EWKKBJnGZQgmQ4zsf38xEAJNFVZbfmYN4AAAAASUVORK5CYII="
          alt=""
        />
        <span class="text_img">{{date}}</span>
      </div>
      <div class="time_left margin-l17">
        <img
          class="time_img"
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAAEKADAAQAAAABAAAAEAAAAAA0VXHyAAABpklEQVQ4EZ1TTUtCQRSdeYUQpIKLCCI3+VZGP8JFaVCudNUuC8Jdy35EuxDMNhIEuoqgVy78EdFbPdsIQrQQ/IBA0OmccV6o4IMauMz9OOfOzL13pFhYB2UvKaQoSCX2scd1WIm2kqIhlKi8ntvuLEX6Rq7mhga90DXsC4gF6YLkMY5kNrYYZAIphaOjy3o+OYKugcKQHdhFSEdKcRyOJjYAP6VQp48xYnCQQw70aQJzcgr2o1wTu07Bfqrn5RikBwp1+hgjBpIyHCH5ZoDe4Oxo8ondh65X+tb7oPJyZu9MPUJk7r2I+hbvsLeUEnsWCwbDQpKiM0P2CYs7McSSQ66lq42CrUcSz4vgZbbBdsldZatQbZfvXEKI+09hHKePh73WFTuEFibZrqBVQ7AdBJDpsufiFptsVcAt5nLkampl0Gt9YbA+LT1hGJJhv3U4hwowDDZGLhLIO2AnaMkNWxTA0yHdRmDJ4WhbjUKCPS1BttHfalASMwNVYsnhv9BF5GzD0YRkOSSZinfEd8LWizp9ZoCycDYNB+Uz67+f6TeBn+iv3/kH6z/De640hPAAAAAASUVORK5CYII="
          alt=""
        />
        <span class="text_img">{{ time }}</span>
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  data() {
    return {
      time: "",
      date: "",
      time_int: null,
    };
  },
  components: {},
  computed: {
    commonHeadTitle() {
      return "东田邻里中心驾驶舱";
    },
  },
  mounted() {
    this.getNowTime();
    this.setInterval();
  },
  methods: {
    //实时获取日期时间
    getNowTime() {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var hour = date.getHours();
      var minute = date.getMinutes();
      var second = date.getSeconds();
      if (month < 10) month = "0" + month;
      if (day < 10) day = "0" + day;
      if (hour < 10) hour = "0" + hour;
      if (minute < 10) minute = "0" + minute;
      if (second < 10) second = "0" + second;
      this.time = hour + ":" + minute + ":" + second;
      this.date = year + "-" + month + "-" + day;
    },
    // 定时
    setInterval() {
      this.time_int = setInterval(() => {
        this.getNowTime();
      }, 1000);
    },
    clearInterval() {
      clearInterval(this.time_int);
      this.time_int = null;
    },
  },
  //
  destroyed() {
    this.clearInterval();
  },
};
</script>
<style lang="scss">
.header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: px2vh(103);
  z-index: 2;
}

.header-content-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: px2vh(103);
  width: 100%;
  background-image: url("~src/assets/images/jsc/top_border.png");
  background-size: 100% 100%;
}

.header-title-text {
  position: absolute;
  left: 50%;
  top: 46%;
  transform: translate3d(-50%, -50%, 0);
  margin-top: px2vh(-10);

  text-shadow: inset 0px 0px 1px rgba(255, 255, 255, 0.5);
  background-image: linear-gradient(180deg, #ffffff 0, #00b4ff 100%);
  overflow-wrap: break-word;
  font-size: px2vh(48);
  font-family: ysbth;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  letter-spacing: px2vh(3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@keyframes pulsate-fwd {
  0% {
    transform: scaleX(1);
  }
  30% {
    transform: scale3d(0.8, 1.25, 1);
    opacity: 1;
  }
  40% {
    transform: scaleY(0.75);
    opacity: 0.5;
  }
  50% {
    transform: scale3d(0.2, 1.15, 1);
    opacity: 0;
  }
  65% {
    transform: scaleY(0.95);
    opacity: 0.5;
  }
  75% {
    transform: scale3d(0.8, 1.05, 1);
    opacity: 1;
  }
  100% {
    transform: scaleX(1);
  }
}
@keyframes pulsates {
  0% {
    left: 34%;
  }
  100% {
    left: 48%;
  }
}
._left_box {
  width: px2vw(600);
  position: relative;
  display: flex;
  align-items: center;
  top: px2vh(48);
  left: px2vw(20);
  .time_left {
    width: px2vw(183);
    height: px2vh(36);
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
      270deg,
      rgba(36, 108, 225, 0) 0,
      rgba(39, 90, 172, 0.6) 52%,
      rgba(58, 114, 203, 0) 100%
    );
    overflow-wrap: break-word;
    color: #fff;
    font-size: px2vh(20);
    font-family: PingFangSC-Medium;
    font-weight: 500;
    text-align: left;
    white-space: nowrap;
    .time_img {
      width: px2vw(15);
      height: px2vh(16);
    }
    .text_img {
      margin-left: px2vw(6);
    }
  }
}
.margin-l17 {
  margin-left: px2vw(17);
}
</style>
